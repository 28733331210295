import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import s from '@commonStyles/footer/Footer.module.scss'
import { HubSpotForm } from '@components/HubSpotForm'
import { useAuthUI, useUser } from '@corratech/pylot-auth-manager'
import { useCmsBlocks } from '@pylot-data/hooks/cms/use-cms-blocks'
import { Maybe, CmsBlock } from '@pylot-data/fwrdschema'
import { useStoreConfig } from '@config/hooks/useStoreConfig'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import cn from 'classnames'
import formStyles from '@commonStyles/Forms.module.scss'
import { usePreloadedData } from '@preload'

const RichContent = dynamic(() =>
    import('@corratech/pylot-rich-content').then((mod) => mod.RichContent)
)

const Footer = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { openAuthModal, onLoginCallback, onSignUpCallback } = useAuthUI()
    const { isSignedIn } = useUser()
    const router = useRouter()
    const {
        base: {
            hubspot: { footer_newslestter }
        }
    } = useStoreConfig()

    const footerPreloadData = usePreloadedData('footer')

    const variables = {
            identifiers: [
                'footer-copyright-block',
                'footer_links',
                'footer-social-media'
            ]
        },
        role = {
            copyright: 'footer-copyright-block',
            links: 'footer_links',
            social: 'footer-social-media'
        }

    const { items } = useCmsBlocks(variables?.identifiers ?? [], {
        fallbackData: footerPreloadData,
        revalidateOnMount: true,
        revalidateOnFocus: false
    })

    const redirectAccount = () => router.push('/account')
    const redirectOrders = () => router.push('/account/orders')

    const openAccount = () => {
        if (!isSignedIn) {
            openAuthModal()
            onLoginCallback([() => redirectAccount()])
            onSignUpCallback([() => redirectAccount()])
        }
    }

    const openOrders = () => {
        if (!isSignedIn) {
            openAuthModal()
            onLoginCallback([() => redirectOrders()])
            onSignUpCallback([() => redirectOrders()])
        }
    }

    const clickHandler = (event: MouseEvent) => {
        const targetElement = event.target as HTMLElement
        if (!targetElement) {
            return
        }
        if (targetElement?.id === 'footer-account') {
            openAccount()
        }
        if (targetElement?.id === 'footer-order') {
            openOrders()
        }
    }

    useEffect(() => {
        const rootElement = document.querySelector('body')
        rootElement?.addEventListener('click', clickHandler, true)
        return () => {
            rootElement?.removeEventListener('click', clickHandler)
        }
    }, [])

    if (!items || !role) {
        return null
    }

    const mappedItems: Record<string, Maybe<CmsBlock>> = {}
    items.forEach((item) => {
        if (item && item.identifier && item.content) {
            mappedItems[item.identifier] = item
        }
    })

    const getBlockOutput = (identifier?: string | null, className?: string) => {
        return (
            identifier &&
            mappedItems[identifier] && (
                <div className={className ? s[className] : ''}>
                    <RichContent html={mappedItems[identifier]?.content} />
                </div>
            )
        )
    }

    return (
        <footer
            className={cn(s.footer, isSignedIn ? s['customer'] : s['guest'])}
        >
            <div className={s['footer-container']}>
                <div className={s['grid-rows']}>
                    <div className={s['grid-left']}>
                        <div className={s['newsletter-form']}>
                            <h2 className={s['newsletter-form-heading']}>
                                {t('Sign up for our Newsletter')}
                            </h2>
                            <p>
                                {t(
                                    'footer|Specials & notifications, right in your inbox!'
                                )}
                            </p>
                            <div
                                className={cn(
                                    formStyles.root,
                                    s['footer-hs-wrapper']
                                )}
                            >
                                <HubSpotForm {...footer_newslestter} />
                            </div>
                        </div>

                        <div
                            className={cn(s['footer-sm-block'], 'desktop-only')}
                        >
                            {getBlockOutput(role.social, 'social')}
                        </div>
                    </div>
                    <div className={s['grid-right']}>
                        <div className={s['footer-links']}>
                            {getBlockOutput(role.links)}
                        </div>
                    </div>
                </div>
                <div className={cn(s['footer-sm-block'], 'mobile-only')}>
                    {getBlockOutput(role.social, 'social')}
                </div>
                <div className={s['footer-copyright-block']}>
                    <div className={s['copyright']}>
                        {getBlockOutput(role.copyright)}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
